/* eslint-disable react-hooks/exhaustive-deps */
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useInView } from 'framer-motion';
import Lottie from 'lottie-react';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Heading from '../../molecules/Heading/Heading';
import Section from '../../molecules/Section/Section';
import { breakpoints } from '../../styles/atoms/breakpoints';
import MobileLottie from './Lottie/MobileLottie';
import {
  AnimationContainer,
  ContentWrapper,
  HeadingWrapper,
  HeroLottieWrapper,
  LottieWrapper,
  Wrapper,
  customHeadingInnerStyles,
  customHeadingStyles,
} from './ScrollableAnimation.style';
import Slide from './Slide/Slide';
import getJsonFromLink from './utils/getJsonFromLink';

const ScrollableAnimation = ({
  id,
  animationInHero,
  lottie,
  keyframes,
  mobileKeyframes,
  headingComponent,
  content,
  contentBgColor,
  hasHexes,
  isNumbered,
}) => {
  const lottieRef = useRef(null);
  const heroRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [lottieJson, setLottieJson] = useState(null);
  const isInView = useInView(heroRef, { amount: 1 });
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  const headingMemo = useMemo(
    () =>
      headingComponent && (
        <Section
          id={headingComponent?.id}
          style={customHeadingStyles(animationInHero)}
          innerStyle={customHeadingInnerStyles(
            animationInHero,
            headingComponent?.removeBottomPadding
          )}
          bgColor={headingComponent?.background}
          hasSvgHexes={
            !headingComponent?.removeBottomPadding && headingComponent?.hasHexes
          }
        >
          <HeadingWrapper ref={heroRef}>
            <Heading
              {...headingComponent.headingBlock[0]}
              alignment={animationInHero ? 'left' : 'center'}
              headingSize={['md', 'lg', 'xxl']}
            />
            {animationInHero && isDesktop && <div />}
            {!isDesktop && lottieJson && (
              <MobileLottie
                lottie={lottieJson}
                start={mobileKeyframes[0].start}
                stop={mobileKeyframes[0].stop}
              />
            )}
          </HeadingWrapper>
        </Section>
      ),
    [animationInHero, headingComponent, isDesktop, lottieJson]
  );

  const desktopLottie = useMemo(
    () => (
      <LottieWrapper includeMargin={isNumbered}>
        {lottieJson && (
          <Lottie
            lottieRef={lottieRef}
            animationData={lottieJson}
            autoplay={false}
            loop={false}
            style={{ width: '570px' }}
          />
        )}
      </LottieWrapper>
    ),
    [lottieJson]
  );

  const contentMemo = useMemo(
    () =>
      content &&
      content.map((slide, ind) => (
        <div key={slide.id}>
          <Slide
            content={slide.headingBlock[0]}
            setCurrentSlide={setCurrentSlide}
            isNumbered={isNumbered}
            index={animationInHero ? ind + 1 : ind}
          />
          {!isDesktop && lottieJson && (
            <MobileLottie
              lottie={lottieJson}
              start={mobileKeyframes[animationInHero ? ind + 1 : ind].start}
              stop={mobileKeyframes[animationInHero ? ind + 1 : ind].stop}
            />
          )}
        </div>
      )),
    [content, lottieJson, isDesktop]
  );

  useLayoutEffect(() => {
    getJsonFromLink(setLottieJson, lottie?.url);
  }, []);

  useEffect(() => {
    if (!isDesktop) return;

    lottieRef?.current?.playSegments(
      [keyframes[currentSlide]?.start, keyframes[currentSlide]?.stop],
      true
    );
  }, [currentSlide]);

  useEffect(() => {
    if (!animationInHero) return;

    isInView && setCurrentSlide(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  useEffect(() => {
    if (!animationInHero) return;

    lottieRef?.current?.playSegments(
      [keyframes[0]?.start, keyframes[0]?.stop],
      true
    );
  }, [lottieJson]);

  return (
    <AnimationContainer>
      {headingComponent && headingMemo}
      <Section
        id={id}
        bgColor={contentBgColor}
        hasSvgHexes={hasHexes}
        hexVariant="wide"
        showYOverflow={headingComponent?.removeBottomPadding}
      >
        <Wrapper>
          <ContentWrapper>
            {contentMemo}
            <Slide setCurrentSlide={setCurrentSlide} index={content.length} />
          </ContentWrapper>
          {isDesktop && !animationInHero && desktopLottie}
        </Wrapper>
      </Section>
      {isDesktop && animationInHero && (
        <HeroLottieWrapper>{desktopLottie}</HeroLottieWrapper>
      )}
    </AnimationContainer>
  );
};

export default ScrollableAnimation;
